<template>
    <div class="c-news-panel">
      <div class="c-social-bar__title">{{ title }} <a :href="href" class="news-title-link"><span class="c-social-bar__subtitle-wide">{{ subtitle }}</span></a><span class="c-social-bar__plus">+</span></div>
      <div class="c-social-bar__subtitle-narrow"><a :href="href" class="news-title-link">{{subtitle}}</a></div>
      <div class="news-items">
        <div v-if="news[0]" class="news-item news-item-1">
          <div class="news-item-image news-item-1-image" :class="{ 'news-item-iframe': news[0].is_iframe }">
            <a :href="news[0].link" class="news-media-link">
              <div v-if="news[0].body" v-html="news[0].body"></div>
              <img v-else-if="news[0].image" :src="news[0].image" />
              <img v-else-if="news[0].showDefaultImage==true" :src="OrderConnectionImage" />
            </a>
          </div>
          <div class="news-info">
            <a :href="news[0].link" class="news-link"><h1 class="news-title">{{ news[0].title }}</h1></a>
            <h2 class="news-subtitle">{{ news[0].subtitle }}</h2>
          </div>
        </div>
        <div v-if="news[1]" class="news-items-col">
          <div class="news-item news-item-2">
            <div class="news-item-image news-item-2-image" :class="{ 'news-item-iframe': news[1].is_iframe }">
              <a :href="news[1].link" class="news-media-link">
                <div v-if="news[1].body" v-html="news[1].body"></div>
                <img v-else :src="news[1].image" />
              </a>
            </div>
            <div class="news-info">
              <a :href="news[1].link" class="news-link"><h1 class="news-title">{{ news[1].title }}</h1></a>
              <h2 class="news-subtitle">{{ news[1].subtitle }}</h2>
            </div>
          </div>
          <div v-if="news[2]" class="news-item news-item-3">
            <div class="news-item-image news-item-3-image" :class="{ 'news-item-iframe': news[2].is_iframe }">
              <a :href="news[2].link" class="news-media-link">
                <div v-if="news[2].body" v-html="news[2].body"></div>
                <img v-else :src="news[2].image" />
              </a>
            </div>
            <div class="news-info">
              <a :href="news[2].link" class="news-link"><h1 class="news-title">{{ news[2].title }}</h1></a>
              <h2 class="news-subtitle">{{ news[2].subtitle }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import OrderConnectionImage from '../../../app/vuejs/pages/images/Order-Connection.png'

export default {
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    href: String,
    news: Array
  },
  data() {
    return {
      OrderConnectionImage
    }
  }
}
</script>

<style lang="scss">
@import '@dharmachakra/tbco-shared-styles/src/assets/scss/partials/_variables.scss';

.c-social-bar__title {
    color: #000000;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing: 1.25pt;
    font-size: 60px;
    font-weight: 700;
    padding-top: 20px;
    flex-wrap: nowrap;
    margin-left: 10px;

    @media(min-width: $mobile-max-size) {
      font-size: 24px;
      letter-spacing: 0.74px;
    }

  @media(min-width: $desktop-size) {
    font-size: 26px;
    margin-bottom: 35px;
    margin-left: 0;
  }
}

@media screen and (max-width: 1150px) {
    .c-social-bar__title {
        margin-bottom: 2px;
    }
}
@media screen and (max-width: $mobile-max-size) {
    .c-social-bar__title {
        letter-spacing: 0.5px;
        font-weight: 700;
        line-height: 16px;
        font-size: 16px;
    }
}

.c-social-bar__subtitle-narrow {
    color: #000000;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing: 0.56pt;
    font-size: 18px;
    font-weight: 600;
    padding-top: 5px;
    flex-wrap: nowrap;
    text-decoration: underline;
    margin-left: 10px;

    @media(min-width: $mobile-max-size) {
      font-size: 20px;
      letter-spacing: 0.63px;
      margin-bottom: 40px;
      padding-top: 0;
    }
}
// hide for desktop
@media screen and (min-width: 1150px) {
    .c-social-bar__subtitle-narrow {
        display: none;
    }
}
@media screen and (max-width: $mobile-max-size) {
    .c-social-bar__subtitle-narrow {
        letter-spacing: 0.47pt;
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 27px;
    }
}

.c-social-bar__subtitle-wide {
    color: #000000;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing: 0.56pt;
    font-size: 20px;
    font-weight: 600;
    flex-wrap: nowrap;
    margin-left: 16px;
}

// hide for tablet
@media screen and (max-width: 1150px) {
    .c-social-bar__subtitle-wide {
        display: none;
    }
}

.c-social-bar__plus {
    color: $colour-orange-burnt;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing: 0.56pt;
    font-size: 26px;
    font-weight: 700;
    flex-wrap: nowrap;
    position: relative;
    top: 2px;
    left: 8px;
}

.news-items {
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  font-family: 'Source Sans Pro', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  iframe {
    width: 100%;
    height: 100%;
  }

  @media(min-width: $desktop-size) {
    flex-direction: row;
  }

  @media(min-width: $desktop-size) {
    gap: 3rem;
  }
}

.news-items-col {
  display: flex;
  flex-direction: column;

  @media(min-width: $mobile-max-size) {
    flex-direction: row;
    gap: 1.5rem;
  }

  @media(min-width: $desktop-size) {
    flex-direction: column;
    gap: 3rem;
    width: calc(30% - 3rem);
    height: 430px;
  }
}

.news-item-1 {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  .news-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media(min-width: $mobile-max-size) {
    flex-direction: row;

    .news-title {
      font-size: 22px;
      letting-space: 0.69px;
      white-space: unset;
      overflow: unset;
      text-overflow: ellipsis;
    }

    .news-subtitle {
      font-size: 20px;
      letting-space: 0.63px;
    }

    .news-info {
      height: unset;
    }
  }

  @media(min-width: $desktop-size) {
    .news-title {
      font-size: 30px;
      letter-spacing: 0.94px;
      line-height: 1.2;
    }

    width: 70%;
    height: 430px;
  }
}

.news-item-3-image {
  display: none;

  * {
    height: 100%;
  }

  @media(min-width: $mobile-max-size) {
    display: block;
  }
}

.news-item-2 {
  flex-shrink: 0;
  height: 282px;

  .news-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .news-item-2-image {
    height: calc(100% - 100px);

    * {
      height: 100%;
    }
  }

  @media(min-width: $mobile-max-size) {
    width: 50%;

    .news-title {
      margin-bottom: 0;
    }

    .news-subtitle {
      line-height: 1.2;
    }
  }

  @media(min-width: $desktop-size) {
    order: 1;
    width: 100%;
  }
}

.news-item-3 {
  flex-shrink: 0;

  .news-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .news-item-3-image {
    height: calc(100% - 100px);

    * {
      height: 100%;
    }
  }

  @media(min-width: $mobile-max-size) {
    width: 50%;
    height: 282px;

    .news-title {
      margin-bottom: 0;
    }

    .news-subtitle {
      line-height: 1.2;
    }
  }

  @media(min-width: $desktop-size) {
    width: 100%;
    height: unset;

    .news-item-3-image {
      display: none;
    }
  }
}

.news-item-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    max-height: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.news-item-1-image {
  flex-shrink: 0;
  height: 180px;

  * {
    height: 100%;
  }

  @media(min-width: $mobile-max-size) {
    width: 72.9%;
    max-height: 315px;
    height: 315px;
  }

  @media(min-width: $desktop-size) {
    height: 100%;
    max-height: 100%;
  }
}


.news-info {
  background-color: #757575;
  color: #fff;
  padding: 5px 18px;
  height: 70px;

  @media(min-width: $mobile-max-size) {
    padding: 10px 20px;
    height: 100px;
  }
}

.news-title {
  font-size: 15px;
  letter-spacing: 0.47px;
  padding-bottom: 0;
  margin-bottom: 0;
  color: #fff;

  @media(min-width: $mobile-max-size) {
    font-size: 22px;
    letter-spacing: 0.69px;
    margin-bottom: 10px;
  }
}

.news-subtitle {
  font-size: 15px;
  letter-spacing: 0.47px;
  margin-bottom: 4px;
  line-height: 18px;
  color: #fff;

  @media(min-width: $mobile-max-size) {
    font-size: 20px;
    letter-spacing: 0.63px;
    font-weight: 600;
    line-height: 1.5;
  }
}

.news-title {
  word-break: break-word;
}

.news-title-link {
  color: #000;
}

.news-link {
  text-decoration: none;
  color: #fff;
}
.news-media-link {
  text-decoration: none;
  color: #262626;
  a {
    text-decoration: underline;
    color: #262626;
  }
}

.news-item-iframe {
  padding-bottom: unset;
}
</style>
